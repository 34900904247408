import * as React from 'react';
import {camelizeKeys} from 'humps';

import {FormValues, CONCACT_CATEGORY_IDS} from './components/FormData';
import Form from './components/Form';
import Confirm from './components/Confirm';

import Layout from '../../shared/Layout';
import AlertModal from '../../shared/modals/AlertModal';

import {formatErrorMessages} from '../../../helpers/errorMessages';

import NavigationProp from '../../../navigators/NavigationProp';

import {NewFaqQuestionRouteProp} from '../../../navigators/RouteProps';

import {Params as WriterContactCreateParams} from '../../../actions/writer/contacts/create';
import {Params as ApplicationModalUpdateParams} from '../../../actions/application_modal/update';

import Contact from '../../../../domain/entities/writer/Contact';
import Question from '../../../../domain/entities/writer/faq/Question';
import CurrentUser from '../../../../domain/entities/writer/CurrentUser';

import TapNovelRestApi from '../../../../data/data_stores/net/TapNovelRestApi';

export interface Params {}

export interface StateProps {
  navigation: NavigationProp;
  route: NewFaqQuestionRouteProp;
  currentUser: CurrentUser | null;
}

export interface DispatchProps {
  createWriterContact: (params: WriterContactCreateParams) => Promise<Contact>;
  updateApplicationModal: (params: ApplicationModalUpdateParams) => void;
}

interface Props extends StateProps, DispatchProps {}

const New: React.FC<Props> = props => {
  const {navigation, currentUser, createWriterContact, updateApplicationModal} =
    props;
  const [loading, setLoading] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState<string | null>(null);
  const [formValues, setFormValues] = React.useState<FormValues | null>(null);
  const onPressBack = React.useCallback(() => {
    setFormValues(null);
  }, []);
  const onPressSubmit = React.useCallback(() => {
    if (!formValues || !currentUser) {
      return null;
    }
    setLoading(true);
    const {selectedCategory, title, content} = formValues;
    if (CONCACT_CATEGORY_IDS.includes(selectedCategory.id)) {
      createWriterContact({
        writerContactCategoryId:
          selectedCategory.id === 3 ? 2 : selectedCategory.id === 7 ? 8 : 6,
        email: currentUser.email,
        content,
      })
        .then(() => {
          setLoading(false);
          navigation.goBack();
          updateApplicationModal({
            options: {
              message:
                selectedCategory.id === 7
                  ? 'お問い合わせが完了いたしました。'
                  : 'お問い合わせが完了いたしました。\n回答には数日かかることもございますので、\nご理解の程よろしくお願いいたします。',
            },
          });
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      TapNovelRestApi.post('/api/writer/faq/questions', {
        question: {
          writerFaqCategoryId: selectedCategory.id,
          title,
          content,
        },
      })
        .then(response => {
          setLoading(false);
          const question = camelizeKeys(response.body) as Question;
          navigation.replace('FaqQuestion', {id: question.id});
          updateApplicationModal({
            options: {
              message:
                'お問い合わせを公開いたしました！\n回答があるまで今しばらく\nお待ちください。',
            },
          });
        })
        .catch(error => {
          setLoading(false);
          setAlertMessage(formatErrorMessages({}, error));
        });
    }
  }, [formValues, currentUser]);
  const onCloseAlertModal = React.useCallback(() => {
    setAlertMessage(null);
  }, []);
  return (
    <Layout
      title={'お問い合わせフォーム'}
      navigation={navigation}
      scrollable={true}
      loading={loading}
      back={true}>
      <Form hidden={!!formValues} onPressConfirm={setFormValues} />
      {formValues ? (
        <Confirm
          formValues={formValues}
          onPressBack={onPressBack}
          onPressSubmit={onPressSubmit}
        />
      ) : null}
      {alertMessage && (
        <AlertModal visible={true} onCloseModal={onCloseAlertModal}>
          {alertMessage}
        </AlertModal>
      )}
    </Layout>
  );
};

export default React.memo(New);
