import * as React from 'react';

import {FormValues} from './components/FormData';
import Form from './components/Form';
import Confirm from './components/Confirm';

import Layout from '../../shared/Layout';
import AlertModal from '../../shared/modals/AlertModal';

import {formatErrorMessages} from '../../../helpers/errorMessages';

import NavigationProp from '../../../navigators/NavigationProp';

import {NewFaqAnswerRouteProp} from '../../../navigators/RouteProps';

import {Params as ApplicationModalUpdateParams} from '../../../actions/application_modal/update';

import TapNovelRestApi from '../../../../data/data_stores/net/TapNovelRestApi';

export interface Params {
  questionId: number;
}

export interface StateProps {
  navigation: NavigationProp;
  route: NewFaqAnswerRouteProp;
}

export interface DispatchProps {
  updateApplicationModal: (params: ApplicationModalUpdateParams) => void;
}

interface Props extends StateProps, DispatchProps {}

const New: React.FunctionComponent<Props> = props => {
  const {navigation, route, updateApplicationModal} = props;
  const {questionId} = route.params;
  const [loading, setLoading] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState<string | null>(null);
  const [formValues, setFormValues] = React.useState<FormValues | null>(null);
  const onPressBack = React.useCallback(() => {
    setFormValues(null);
  }, []);
  const onPressSubmit = React.useCallback(() => {
    if (!formValues) {
      return null;
    }
    setLoading(true);
    const {content} = formValues;
    TapNovelRestApi.post('/api/writer/faq/answers', {
      answer: {
        writerFaqQuestionId: questionId,
        content,
      },
    })
      .then(response => {
        setLoading(false);
        navigation.goBack();
        updateApplicationModal({
          options: {
            message:
              'TapNovelMakerは皆様の力添えに\nよって支えられております。\n\nより良いサービスにしていけるよう\n努めて参りますので、 \n今後ともTapNovelMakerを\nよろしくお願いいたします。',
            title: '回答ありがとうございました！',
          },
        });
      })
      .catch(error => {
        setLoading(false);
        setAlertMessage(formatErrorMessages({}, error));
      });
  }, [formValues]);
  const onCloseAlertModal = React.useCallback(() => {
    setAlertMessage(null);
  }, []);
  return (
    <Layout
      title={'回答フォーム'}
      navigation={navigation}
      scrollable={true}
      loading={loading}
      back={true}>
      <Form hidden={!!formValues} onPressConfirm={setFormValues} />
      {formValues ? (
        <Confirm
          formValues={formValues}
          onPressBack={onPressBack}
          onPressSubmit={onPressSubmit}
        />
      ) : null}
      {alertMessage && (
        <AlertModal visible={true} onCloseModal={onCloseAlertModal}>
          {alertMessage}
        </AlertModal>
      )}
    </Layout>
  );
};

export default React.memo(New);
